import { Dialog, Zoom } from "@mui/material";
import clsx from "clsx";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close-x.svg";
import { Button } from ".././../atoms/button/Button";

import { useStyles } from "./Modal.styles";
import { ModalProps } from "./Modal.types";
import { ModalFooter } from "./modalFooter/ModalFooter";

export const Modal = ({
  children,
  open,
  onClose,
  className,
  isCloseIcon = true,
  isCloseButton,
  isDisabled,
  ...props
}: ModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      className={clsx(classes.modal, className)}
      TransitionComponent={Zoom}
      open={open}
      onClose={onClose}
      {...props}
    >
      <div className={classes.dialogHeader}>
        {isCloseIcon && (
          <div
            className={clsx(classes.close, isDisabled && classes.isDisabled)}
            aria-label="close"
            onClick={(e) => onClose(e, "escapeKeyDown")}
            data-testid="modal-close-button"
          >
            <CloseIcon />
          </div>
        )}
        {isCloseButton && (
          <Button
            variant="outlined"
            onClick={(e) => onClose(e, "escapeKeyDown")}
            bold
          >
            Exit
          </Button>
        )}
      </div>
      {children}
    </Dialog>
  );
};

Modal.Footer = ModalFooter;
