import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetAllSettingProps,
  GetSettingManagement,
  updateSetting,
  updateSettingProps,
  updateSettingSucessResponse,
  // UpdateAdminStatusProps,
  // UpdateAdminStatusResponseData,
} from "./settings.types";

const getAdminUsers = ({ client }: GetAllSettingProps) =>
  client
    .get<GetSettingManagement>("/api/auth/setting")
    .then(({ data }) => data);

export const useGetSetting = ({ client }: GetAllSettingProps) =>
  useQuery(["getSetting"], () => getAdminUsers({ client }));

const editSetting = ({ client, body }: updateSettingProps) =>
  client
    .put<updateSettingSucessResponse>(`/api/auth/setting`, body, {
      headers: { "Content-Type": "application/json " },
    })
    .then(({ data }) => data);

export const useEditSetting = (
  options?:
    | UseMutationOptions<
        updateSettingSucessResponse,
        unknown,
        Pick<updateSetting, "body">,
        unknown
      >
    | undefined
) => useMutation(editSetting, options);

// const editAdminStatus = ({ client, body, id }: UpdateAdminStatusProps) =>
//   client
//     .put<UpdateAdminStatusResponseData>(`/api/activeUser/${id}`, body)
//     .then(({ data }) => data);

// export const useEditAdminStatus = (
//   options?:
//     | UseMutationOptions<
//         UpdateAdminStatusResponseData,
//         unknown,
//         Pick<UpdateAdminStatusProps, "body">,
//         unknown
//       >
//     | undefined
// ) => useMutation(editAdminStatus, options);
