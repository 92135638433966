import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";

import { FormSchema } from "./Login.types";

const schema = ({ email, password, remember_me }: FormSchema) =>
  Joi.object<FormSchema>({
    email: Joi.string().required().messages(email),
    password: Joi.string().required().messages(password),
    remember_me: Joi.boolean().messages(remember_me).default(true),
  });

export const LoginSchema = (formatMessage: IntlFormatters["formatMessage"]) =>
  joiResolver(
    schema({
      email: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      password: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      remember_me: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
