import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UseStylesProps } from "./Button.types";

const makeBorder = (color: string) => `solid 2px ${color}`;

export const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => {
  const border = makeBorder(palette.black.main);
  const outlinedActiveColor = palette.black.main;
  const borderRadius = "4px";
  return {
    text: {
      border: makeBorder("transparent"),
    },
    containedPrimary: {
      border,
      color: palette.black.contrastText,
      backgroundColor: palette.black.main,
      "&:focus:active": {
        borderColor: palette.action.active,
        backgroundColor: palette.action.active,
      },
      "&:hover": {
        borderColor: palette.action.hover,
        backgroundColor: palette.action.hover,
      },
      "&:disabled": {
        border: makeBorder(palette.action.disabledBackground),
        color: palette.black.contrastText,
        backgroundColor: palette.action.disabledBackground,
      },
    },
    outlinedPrimary: {
      border,
      color: palette.black.main,
      backgroundColor: palette.black.contrastText,
      "&:focus:active": {
        border: makeBorder(outlinedActiveColor),
        color: palette.black.contrastText,
        backgroundColor: outlinedActiveColor,
      },
      "&:hover": {
        border,
        color: palette.black.main,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
      "&:disabled": {
        border: makeBorder(palette.action.disabledBackground),
        color: palette.action.disabledBackground,
        backgroundColor: palette.black.contrastText,
      },
    },
    root: {
      fontSize: "16px",
      lineHeight: "19px",
      padding: "9px 18px 8px 18px",
      borderRadius,
      fontWeight: ({ bold }) => (bold ? "bold" : "normal"),
      textTransform: ({ upperCase }) => (upperCase ? "uppercase" : "none"),
    },
    sizeSmall: {
      fontSize: "14px",
      lineHeight: "16px",
      padding: "6px 18px",
      borderRadius,
    },
    sizeLarge: {
      fontSize: "21px",
      lineHeight: "24px",
      padding: "11px 18px 9px 18px",
      borderRadius,
    },
    iconSizeSmall: { "& > *:first-child": { fontSize: "16px" } },
    iconSizeMedium: { "& > *:first-child": { fontSize: "19px" } },
    iconSizeLarge: { "& > *:first-child": { fontSize: "24px" } },
  };
});
