import { useEditAdminStatus } from "../../../api/userManagement/userManagement";
import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../hooks/useClient/useClient";
import { useState } from "react";
import { ManageUsers } from "./ManageUser";

export const ManageUsersContainer = () => {
  const client = useClient();
  const [adminStatus, setAdminStatus] = useState("");
  const [id, setId] = useState(0);

  const { mutate: editAdminStatus } = useEditAdminStatus({
    onSuccess: () => {
      toast.success("Admin status updated successfully");
    },
    onError: () => {
      toast.error("Failed to update admin status");
    },
  });

  const handleOnSubmit = async () => {
    await editAdminStatus({
      id: id,
      client,
      body: {
        is_active: adminStatus,
      },
    });
  };

  return (
    <>
      <ManageUsers
        onSubmit={handleOnSubmit}
        setAdminStatus={setAdminStatus}
        setId={setId}
      />
      <ToastContainer />
    </>
  );
};
