import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ palette: { gray, red }, spacing }) => ({
    outer: {
      border: "2px dashed !important",
      borderColor: gray.light,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      textAlign: "center",
      cursor: "pointer",
      padding: spacing(5),
    },
    label: {
      color: red.main,
    },
    content: {},
    description: {
      fontSize: `${spacing(1.5)} !important`,
      color: gray.dark,
    },
  })
);
