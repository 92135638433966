import Dropzone from "react-dropzone";

import { useStyles } from "./DropZoneBox.styles";
import { DropzoneBoxProps } from "./DropZoneBox.types";

export const DropzoneBox = ({
  children,
  onDrop,
  multiple,
  description,
  accept,
  ...props
}: DropzoneBoxProps) => {
  const styles = useStyles();

  return (
    <Dropzone {...props} onDrop={onDrop} multiple={multiple} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} className={styles.outer}>
            <input
              {...getInputProps()}
              // onChange={({ target: { value } }) => onChange(value)}
            />
            <div className={styles.label}>{children}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
