import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type UseStylesProps = {
  bold?: boolean;
};

export const useStyles = makeStyles<Theme, UseStylesProps>(({ spacing }) => ({
  hero: {
    fontSize: spacing(10),
    lineHeight: spacing(15),
  },
  header: {
    fontWeight: ({ bold }) => (bold ? "bold" : "normal"),
  },
}));
