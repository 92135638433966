import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UseStylesProps } from "./ListMenuOption.types";

const columnStyles = {
  display: "flex",
  flexDirection: "column",
} as const;

const centerStyles = {
  ...columnStyles,
  alignItems: "center",
} as const;

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ spacing, palette: { black, gray }, typography: { fontWeightBold } }) => ({
    listItem: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      padding: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? `${spacing(0, 2)} !important` : 0,
      marginBottom: `${spacing(3)} !important`,
    },
    navigationItemInnerWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? "space-between" : "center",
      borderRadius: spacing(0.5),
      marginBottom: `${spacing(0.5)} !important`,
      alignItems: "center",
      background: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? `${gray.light} !important` : 0,
    },
    navigationDetails: {
      display: "flex",
      alignItems: "center",
    },
    navigationText: {
      ...centerStyles,
      justifyContent: "center",
      fontWeight: fontWeightBold,
      marginLeft: spacing(2),
      marginRight: `${spacing(1)} !important`,
      "&:hover": {
        color: black.main,
      },
    },
    navigationIcon: {
      ...centerStyles,
      justifyContent: "center",
      height: spacing(5),
      width: spacing(5),
      borderRadius: spacing(0.5),
      marginRight: `${spacing(1)} !important`,
    },
    collapseContainer: {
      display: "flex",
      flexDirection: "column",
      gap: ` ${spacing(0.2)} !important`,
      paddingLeft: spacing(2),
    },
  })
);
