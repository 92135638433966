import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import authenticationReducer from "./authenticationReducer/authenticationReducer";
import { createLogger } from "redux-logger";

const middlewares = [];

if (process.env.REACT_APP_ENV === "development") {
  middlewares.push(createLogger());
}

export const store = configureStore({
  reducer: {
    authentication: persistReducer(
      {
        key: "authentication",
        storage,
        blacklist: [],
      },
      authenticationReducer
    ),
  },
  middleware: middlewares,
  devTools: process.env.REACT_APP_ENV === "development",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
