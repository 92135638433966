import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetUserManagement,
  GetAllUserProps,
  UpdateAdminStatusProps,
  UpdateAdminStatusResponseData,
} from "./userManagement.types";

const getAdminUsers = ({ client }: GetAllUserProps) =>
  client
    .get<GetUserManagement>("/api/auth/admin/users")
    .then(({ data }) => data);

export const useGetAdminUsers = ({ client }: GetAllUserProps) =>
  useQuery(["getAdminUsers"], () => getAdminUsers({ client }));

const editAdminStatus = ({ client, body, id }: UpdateAdminStatusProps) =>
  client
    .put<UpdateAdminStatusResponseData>(`/api/activeUser/${id}`, body)
    .then(({ data }) => data);

export const useEditAdminStatus = (
  options?:
    | UseMutationOptions<
        UpdateAdminStatusResponseData,
        unknown,
        Pick<UpdateAdminStatusProps, "body">,
        unknown
      >
    | undefined
) => useMutation(editAdminStatus, options);
