import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ palette, spacing, typography: { body1, fontWeightBold } }) => ({
    table: {
      rowGap: spacing(2),
      width: "100%",
    },
    th: {
      ...body1,
      color: palette.black.dark,
      padding: spacing(2.5, 1, 0, 1),
      fontSize: spacing(2),
      fontWeight: "bold",
      "&:first-child": {
        padding: spacing(2.5, 1, 0, 0),
      },
    },
    tr: {
      display: "contents",
    },
    td: {
      ...body1,
      display: "flex",
      position: "relative",
      alignItems: "center",
      padding: spacing(2, 1),
      "& *": {
        zIndex: 2,
      },
      "&:first-child": {
        borderRadius: spacing(0.5, 0, 0, 0.5),
      },
      "&:last-child": {
        borderRadius: spacing(0, 0.5, 0.5, 0),
      },
      "&::before, &::after": {
        width: "100%",
        height: "100%",
        position: "absolute",
        content: '""',
        left: 0,
      },
      "&::before": {
        boxShadow:
          "0 4px 8px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)",
        zIndex: 0,
      },
      "&::after": {
        backgroundColor: palette.white.main,
        zIndex: 1,
      },
    },

    sortingChar: {
      color: palette.green.main,
      fontWeight: fontWeightBold,
      position: "absolute",
      marginLeft: spacing(0.5),
    },
  })
);
