import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, palette, spacing, typography }) => ({
    main: {
      gridTemplateColumns: "repeat(2, 50%)",
      minHeight: "100vh",
      background: "#1D1644",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
      [breakpoints.down("lg")]: {
        gridTemplateColumns: "100%",
      },
    },
    logo: {
      width: spacing(20.25),
      height: spacing(20.25),
      marginBottom: spacing(2),
    },
    card: {
      width: spacing(70),
      height: spacing(60),
      borderRadius: spacing(10),
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
      padding: spacing(5, 4, 3, 4),
    },
    cardForm: {
      width: spacing(70),
      height: spacing(60),
      borderRadius: spacing(10),
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
      padding: spacing(3, 4, 3, 4),
      // gap: spacing(2),
    },
    header: {
      fontFamily: "",
      marginBottom: spacing(5),
      fontSize: spacing(10),
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: typography.h3.fontSize,
        lineHeight: typography.h3.lineHeight,
      },
    },
    input: {
      fontFamily: "",
      marginBottom: ` ${spacing(5)} !important`,
      paddingBottom: spacing(2),
      color: "#212121",
    },
    submit: {
      height: `${spacing(5)} !important`,
      // width: `${spacing(15)} !important`,
      backgroundColor: "#212121 !important",
      color: "white !important",
    },
    buttonDiv: {
      width: "100% !important",
      display: "flex !important",
      justifyContent: "center !important",
      height: "12% !important",
      alignItems: "center !important",
    },
  })
);
