import clsx from "clsx";

import { useStyles } from "./Heading.styles";
import { HeadingProps } from "./Heading.types";
import { Typography } from "@mui/material";

export const Heading = ({
  bold,
  hero,
  className,
  children,
  ...props
}: HeadingProps) => {
  const classes = useStyles({ bold });
  const containerStyles = clsx(classes.header, className);

  return (
    <Typography
      className={clsx(containerStyles, { [classes.hero]: hero })}
      {...props}
    >
      {children}
    </Typography>
  );
};
