import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { useLocale } from "../../../../../../hooks/useLocale/useLocale";

import { Typography } from "../../../../../../ui/atoms";

import { MenuOption } from "../MenuOption";

import { useStyles } from "./ListMenuOption.styles";
import { ListMenuOptionProps } from "./ListMenuOption.types";

export const ListMenuOption = ({
  id,
  icon,
  isSidebarExpanded,
  handleSidebarDisplayed,
  subMenuItems,
}: ListMenuOptionProps) => {
  const { formatMessage } = useLocale();

  const [open, setOpen] = useState(false);
  const styles = useStyles({ isSidebarExpanded });

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={clsx(styles.listItem)}
      onClick={() => handleClick()}
    >
      <div
        className={clsx(
          "navigationItemInnerWrapper",
          styles.navigationItemInnerWrapper
        )}
      >
        <div className={styles.navigationDetails}>
          <div className={clsx("navigationIcon", styles.navigationIcon)}>
            {icon}
          </div>
          {isSidebarExpanded && (
            <Typography variant="body1" className={styles.navigationText}>
              {formatMessage({ id: id as string })}
            </Typography>
          )}
        </div>
        {isSidebarExpanded && (open ? <ExpandLess /> : <ExpandMore />)}
      </div>

      <Collapse in={open} unmountOnExit className={styles.collapseContainer}>
        {isSidebarExpanded &&
          subMenuItems?.map(({ icon, id, path }) => (
            <MenuOption
              key={id}
              isSidebarExpanded={isSidebarExpanded}
              handleSidebarDisplayed={handleSidebarDisplayed}
              id={id}
              path={path}
              icon={icon}
            />
          ))}
      </Collapse>
    </List>
  );
};
