import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({
    breakpoints,
    spacing,
    typography: { fontWeightBold, h3 },
    palette: { darkGray, white, red },
  }) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      //   alignItems: "center",
      height: "100%",
    },
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      margin: spacing(4),
      padding: spacing(5),
      backgroundColor: white.main,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(1.5),
    },
    textBox: {
      color: darkGray.main,

      [breakpoints.up("lg")]: {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    header: {
      marginBottom: spacing(4),

      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: h3.fontSize,
        lineHeight: h3.lineHeight,
      },
    },
    input: {
      marginBottom: spacing(2),

      [breakpoints.down("lg")]: {
        marginBottom: spacing(3),
      },
    },
    bold: {
      fontWeight: fontWeightBold,
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: spacing(1.25),

      [breakpoints.between("sm", "lg")]: {
        "& a": {
          color: white.main,
        },
      },
    },
    head: {
      display: "flex",
      FlexDirection: "column",
      width: "100%",
      marginBottom: spacing(5),
    },
    filterElements: {
      marginRight: spacing(2.5),
      width: spacing(50),
    },
    buttons: {
      backgroundColor: `${white.main} !important`,
      color: `${red.main} !important`,
      border: `2px solid ${red.main} !important`,
      width: spacing(17),
    },
  })
);
