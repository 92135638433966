import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { ReactNode } from "react";

import { breakpoints } from "./breakpoints";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

const theme = createTheme({
  typography,
  palette,
  breakpoints,
  shadows,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        sizeLarge: {
          fontSize: 21,
          padding: "6px 22px",
        },
        sizeSmall: {
          fontSize: 14,
        },
      },
    },
  },
});

export const Theme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
