import { Card } from "@mui/material";
import { Input, Typography, Button } from "../../ui/atoms";
import { useStyles } from "./Login.styles";
import { ReactComponent as FlashLogo } from "../../assets/img/trace.svg";
import { LoginProps } from "./Login.types";

export const Login = ({ register, onSubmit, errors }: LoginProps) => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <FlashLogo className={styles.logo} />
      <Card
        elevation={10}
        className={styles.card}
        style={{ borderRadius: "10%" }}
      >
        <Typography variant="h4" className={styles.header}>
          Login Here
        </Typography>
        <form noValidate onSubmit={onSubmit} className={styles.cardForm}>
          <Input
            boldLabel
            label={"Email"}
            name="email"
            type="email"
            aria-label="Email address"
            className={styles.input}
            placeholder={"Enter email address"}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            register={register}
          />
          <Input
            boldLabel
            label={"Password"}
            name="password"
            type="password"
            aria-label="Password"
            className={styles.input}
            placeholder={"Enter Password"}
            error={!!errors?.password}
            helperText={errors?.password?.message}
            register={register}
          />
          {/* <Typography style={{ color: "red", marginBottom: 15, marginTop: 15 }}>
            <a href="/forgot-password">Forgot password?</a>
          </Typography> */}
          <div className={styles.buttonDiv}>
            <Button type="submit" size="large" bold className={styles.submit}>
              Login
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};
