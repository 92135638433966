import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetAppUserManagement,
  GetAllUserProps,
  ActivateAdminUsers,
  getAppUsers,
} from "./customerManagement.types";

const getAppUser = ({ client, searchValue, page }: GetAllUserProps) =>
  client
    .get<GetAppUserManagement>(
      `/api/auth/getusers?search=${searchValue}&page=${page}`
    )
    .then(({ data }) => data);

export const useGetAppUsers = ({
  client,
  searchValue,
  page,
}: GetAllUserProps) =>
  useQuery(["getAppUsers"], () => getAppUser({ client, searchValue, page }), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
  });

const activateAdmin = ({ client, body, id }: ActivateAdminUsers) =>
  client
    .put<getAppUsers>(`/api/activeUser/${id}`, body)
    .then(({ data }) => data);

export const useActivateAdmin = (
  options?:
    | UseMutationOptions<
        getAppUsers,
        unknown,
        Pick<ActivateAdminUsers, "body">,
        unknown
      >
    | undefined
) => useMutation(activateAdmin, options);
