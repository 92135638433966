import { useDeleteDocumentById } from "../../../../api/document/Document";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { DocumentGenerator } from "./DocumentGenerator";
import { useClient } from "../../../../hooks/useClient/useClient";
export const DocumentGeneratorContainer = ({
  id,
}: {
  id: string | undefined;
}) => {
  const client = useClient();

  const { mutate: deleteBoardRoom } = useDeleteDocumentById({
    onSuccess: () => {
      toast.success("Document deleted successfully");
    },
    onError: () => {
      toast.error("Failed to delete document");
    },
  });

  const handleDelete = async (id: number) => {
    await deleteBoardRoom({ client, id });
  };

  return (
    <>
      <DocumentGenerator handleDelete={handleDelete} id={id} />
      <ToastContainer autoClose={3000} />
    </>
  );
};
