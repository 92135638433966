import { useQuery } from "react-query";
import {
  GetDashBoardSuccessResponse,
  GetAllBoardRoomProps,
} from "./Dashboard.types";

const getDashBoard = ({ client }: GetAllBoardRoomProps) =>
  client
    .get<GetDashBoardSuccessResponse>("/api/auth/dashboard/stats")
    .then(({ data }) => data);

export const useGetDashBoard = ({ client }: GetAllBoardRoomProps) =>
  useQuery(["getDashBoard"], () => getDashBoard({ client }));
