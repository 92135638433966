import * as React from "react";
import { useStyles } from "./settings.styles";
import { Button, Heading, Input, Typography } from "../../../ui/atoms";
import { updateSettingProps } from "./Settings.types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PushNotificationContainer } from "../../pushNotification/PushNotificationContainer";
import { ManageUsersContainer } from "../../userManagement/manageUser/ManageUsersContainer";
import { colors } from "../../../constants/colors";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Settings = ({
  onSubmit,
  register,
  settingData,
}: updateSettingProps) => {
  const styles = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Settings Managements</h1>
      </Heading>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: { background: colors.primary },
            }}
            style={{
              color: colors.primary,
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": { backgroundColor: colors.medium },
            }}
            textColor="inherit"
          >
            <Tab
              label="Notifications"
              sx={{ fontWeight: "600" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Delivery Rate"
              sx={{ fontWeight: "600" }}
              {...a11yProps(1)}
            />
            <Tab
              label="User Management"
              sx={{ fontWeight: "600" }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PushNotificationContainer />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className={styles.root2}>
            <div className={styles.flexBox}>
              <h2
                style={{
                  fontWeight: "bold",
                  marginRight: 20,
                  height: 280,
                  // backgroundColor: "rosybrown",
                }}
              >
                Standard Delivery
              </h2>
              <h2
                style={{
                  fontWeight: "bold",
                  marginRight: 20,
                  // backgroundColor: "rosybrown",
                }}
              >
                Express Delivery
              </h2>
            </div>
            <form noValidate onSubmit={onSubmit} className={styles.formCard}>
              <div className={styles.right1}>
                <Input
                  multiline
                  rows="3"
                  register={register}
                  name="standardDescription"
                  label="Description"
                  defaultValue={
                    settingData?.find(
                      (data) => data.key === "standard_delivery"
                    )?.description
                  }
                  style={{
                    marginBottom: 20,
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "500", color: "#344054" },
                  }}
                />

                <Input
                  register={register}
                  name="standardAmount"
                  defaultValue={
                    settingData?.find(
                      (data) => data.key === "standard_delivery"
                    )?.value
                  }
                  label="Amount"
                  InputLabelProps={{
                    style: { fontWeight: "500", color: "#344054" },
                  }}
                />
              </div>

              <div className={styles.right1}>
                <Input
                  multiline
                  rows="3"
                  name="expressDescription"
                  register={register}
                  label="Description"
                  defaultValue={
                    settingData?.find((data) => data.key === "express_delivery")
                      ?.description
                  }
                  style={{
                    marginBottom: 20,
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "500", color: "#344054" },
                  }}
                />

                <Input
                  // className={styles.amountInput}
                  name="expressAmount"
                  register={register}
                  label="Amount"
                  // type="number"
                  defaultValue={
                    settingData?.find((data) => data.key === "express_delivery")
                      ?.value
                  }
                  InputLabelProps={{
                    style: { fontWeight: "500", color: "#344054" },
                  }}
                />
              </div>

              <div className={styles.updateButton}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  bold
                  className={styles.buttonUpdate}
                  style={{ backgroundColor: colors.primary }}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ManageUsersContainer />
        </CustomTabPanel>
      </Box>
    </div>
  );
};
