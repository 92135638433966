import LoadingIcon from "../../assets/icons/loading.gif";
import { useClient } from "../../hooks/useClient/useClient";
import { useGetDashBoard } from "../../api/dashboard/Dashboard";
import { Dashboard } from "./Dashboard";

export const DashboardContainer = () => {
  const client = useClient();

  const { data: dashboard, isLoading } = useGetDashBoard({
    client,
  });

  return (
    <>
      <Dashboard dashboard={dashboard} />

      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
    </>
  );
};
