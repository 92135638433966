import { useState } from "react";
import { useDispatch } from "react-redux";
import LoadingIcon from "../../assets/icons/loading.gif";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useLogin } from "../../api/signin/signin";
import { useNavigate } from "react-router-dom";
import { onLoginSuccess } from "../../store/authenticationReducer/authenticationReducer";
import { AppRoute } from "../../routing/AppRoute.enum";
import { useForm } from "react-hook-form";
import { LoginFormInputs } from "./Login.types";
import { LoginSchema } from "./Login.utils";
import { useLocale } from "../../hooks/useLocale/useLocale";
import { Login } from "./Login";

export const LoginContainer = () => {
  const { formatMessage } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: mutateOnLogin } = useLogin({
    onSuccess: ({ access_token, token_type, expires_at, user_id }) => {
      setIsLoading(false);
      dispatch(
        onLoginSuccess({
          isAuthorized: true,
          loginUserDetails: {
            access_token: access_token,
            token_type: token_type,
            expires_at: expires_at,
            user_id: user_id,
          },
        })
      );
      console.log("Login success", user_id);
      localStorage.setItem("boardRoomUserId", user_id as unknown as string);

      toast.success("Login Success");
      navigate(AppRoute.dashboard);
    },
    onError: (error) => {
      setIsLoading(false);
      toast.error("Login Failed");
      console.log("Login Error", error);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: LoginSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const handleOnSubmit = async (values: LoginFormInputs) => {
    setIsLoading(true);
    await mutateOnLogin(values);
  };

  return (
    <>
      <Login
        register={register}
        onSubmit={handleSubmit(handleOnSubmit)}
        errors={errors}
      />
      <ToastContainer />
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
    </>
  );
};
