import { Card, Input, Typography, Button } from "../../../ui/atoms";
import { useStyles } from "./CreateCustomer.styles";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../routing/AppRoute.enum";

export const CreateCustomer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <Typography className={styles.header}>Create Customer</Typography>
      <Card elevation={10} className={styles.card}>
        <div className={styles.rowDiv}>
          <Input
            required
            label={"User Name"}
            aria-label="username"
            placeholder={"Enter user name"}
            name="username"
            className={styles.input}
          />
          <Input
            required
            label={"User Type"}
            aria-label="userType"
            placeholder={"Enter user type"}
            name="userType"
            className={styles.input}
          />
        </div>
        <div className={styles.rowDiv}>
          <Input
            required
            label={"User Email"}
            aria-label="userEmail"
            placeholder={"Enter user email"}
            name="userEmail"
            className={styles.input}
          />
          <Input
            required
            label={"User Name"}
            aria-label="username"
            placeholder={"Enter user name"}
            name="username"
            className={styles.input}
          />
        </div>

        <div className={styles.rowDiv}>
          <Input
            required
            label={"User Email"}
            aria-label="userEmail"
            placeholder={"Enter user email"}
            name="userEmail"
            className={styles.input}
          />
          <Input
            required
            label={"User Name"}
            aria-label="username"
            placeholder={"Enter user name"}
            name="username"
            className={styles.input}
          />
        </div>

        <div className={styles.buttonRow}>
          <Button
            type="submit"
            size="large"
            variant="outlined"
            bold
            className={styles.cancel}
            onClick={() => navigate(AppRoute.manageCustomers)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="large"
            variant="outlined"
            bold
            className={styles.submit}
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
};
