import { createSlice } from "@reduxjs/toolkit";

import { AuthenticationState } from "./authenticationReducer.types";

export const authenticationInitialState: AuthenticationState = {
  // isAuthorized: false,
  loginUserDetails: {
    access_token: "",
  },
};

export const authentication = createSlice({
  name: "authentication",
  initialState: authenticationInitialState,
  reducers: {
    onLoginSuccess: (state, { payload }) => {
      state.loginUserDetails = payload.loginUserDetails;
    },
    onLogOutAction: (state) => {
      state.loginUserDetails = authenticationInitialState.loginUserDetails;
    },
    onSetTokens: (state, { payload }) => {
      state.loginUserDetails = {
        ...state.loginUserDetails,
        access_token: payload.access_token,
      };
    },
  },
  extraReducers: {},
});

export const { onLoginSuccess, onLogOutAction, onSetTokens } =
  authentication.actions;

// eslint-disable-next-line import/no-default-export
export default authentication.reducer;
