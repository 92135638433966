import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({
    breakpoints,
    spacing,
    typography: { fontWeightBold, h3 },
    palette: { darkGray, white, red, black, green },
  }) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "auto",
      backgroundColor: red.main,
    },
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      margin: spacing(4),
      padding: spacing(5),
      backgroundColor: white.main,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(1.5),
    },
    textBox: {
      color: darkGray.main,

      [breakpoints.up("lg")]: {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    header: {
      marginBottom: spacing(4),

      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: h3.fontSize,
        lineHeight: h3.lineHeight,
      },
    },
    input: {
      marginBottom: spacing(2),

      [breakpoints.down("lg")]: {
        marginBottom: spacing(3),
      },
    },
    bold: {
      fontWeight: fontWeightBold,
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: spacing(1.25),

      [breakpoints.between("sm", "lg")]: {
        "& a": {
          color: white.main,
        },
      },
    },
    table: {
      gridTemplateColumns: () =>
        `${spacing(50)} ${spacing(45)}  ${spacing(50)}  !important`,
      '& [class*="td"]:last-child': {
        marginLeft: spacing(-3),
        justifyContent: "flex-start",
        paddingRight: spacing(7),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
    },
    buttonView: {
      color: `${red.main} !important`,
      borderColor: `${red.main} !important`,
    },
    actionButtons: {
      display: "flex",
      gap: spacing(1),
      justifyContent: "center !important",
      alignItems: "center !important",
      alignSelf: "center !important",
    },

    action: {
      display: "flex",
      gap: spacing(1),
    },
    buttonRemove: {
      color: `${red.main} !important`,
      borderColor: `${red.main} !important`,
    },
    truncatedText: {
      height: spacing(5),
      display: "flex",
      justifyContent: "center !important",
      alignItems: "center !important",
      alignSelf: "center !important",
    },
    mainDiv: {
      padding: spacing(3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      gap: spacing(2),
    },
    submit: {
      color: "black !important",
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItems: "flex-end !important",
      alignSelf: "flex-end !important",
      borderColor: "black !important",
      fontWeight: "bold !important",
    },
    heading: {
      fontSize: spacing(5),
      fontWeight: "bold",
    },
    buttonDiv: {
      display: "contents",
      marginBottom: "200px !important",
      backgroundColor: "red !important",
    },
    head: {
      display: "flex",
      FlexDirection: "column",
      width: "100%",
      marginBottom: spacing(3),
    },

    title: {
      color: black.main,
      fontWeight: `${500} !important`,
      margin: `${spacing(1.7, 2, 0, 0)} !important`,
    },
    lable: {
      fontSize: spacing(3),
      color: black.main,
      fontWeight: `${600} !important`,
      margin: `${spacing(1.7, 2, 0, 0)} !important`,
    },
    buttonyes: {
      margin: `${spacing(1, 2)} !important`,
      color: `${red.main} !important`,
      borderColor: `${red.main} !important`,
    },

    closeButton: {
      margin: `${spacing(1, 2)} !important`,
      color: `${green.main} !important`,
      borderColor: `${green.main} !important`,
    },
    rowDiv: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(2),
      marginBottom: spacing(3),
      width: "100%",
    },
  })
);
